.forget_sub-heading {
  text-align: center;
  text-transform: lowercase;
  font-size: 0.9rem;
  color: #7e7575;
  align-self: center;
}
.forget_heading {
  align-self: center;
}

.forget_sub-heading p::first-letter {
  text-transform: capitalize;
}
.formforget .form-button {
  border-radius: 11px;
  background-color: rgb(81, 86, 190);
  color: #ffff;
  width: 100%;
  font-size: 1rem;
  text-transform: capitalize;
  padding: 4px;
}
.formforget .form-button:hover {
  background-color: rgb(81, 86, 190);
}
.formforget input {
  width: 100%;
  border: none;
  background: transparent;
  border-bottom: 2px solid #7e7575;
  margin: 10px 0;
  outline: none;
}
.log_img {
  align-self: center;
}
.log_img img {
  width: 200px;
  object-fit: cover;
}
.alert_danger {
  color: #b14542;
  background-color: #fec0bf;
  border-color: #feb1af;
  margin: 0;
  border: 2px solid transparent;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 2px solid transparent;
  border-radius: 0.25rem;
  font-size: 0.7rem;
  text-align: center;
}
.alert_green {
  color: black;
  background-color: #0080008f;
  border-color: #0080008f;
  margin: 0;
  border: 2px solid transparent;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 2px solid transparent;
  border-radius: 0.25rem;
  font-size: 0.7rem;
  text-align: center;
}
.emailsend {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 404px;
  padding: 10px;
}
.emailsend h3 {
  font-weight: 800;
}

.emailsend h6 {
  color: #464040;
  text-align: justify;
  font-size: 0.9rem;
  line-height: 20px;
  font-weight: 500;
}
