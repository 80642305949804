@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,500;0,600;1,400;1,500&family=Roboto:ital,wght@0,100;0,300;1,500&display=swap");

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}
.container {
  display: flex;
}

main {
  width: 100%;
  padding: 20px;
}
.sidebar {
  background: #5156be;
  color: #fff;
  height: 100vh;

  width: 100wh;
  transition: width 100wh, height 100vh;
  /* transition: all 0.5s; */
}
.top_section {
  display: flex;
  align-items: center;
  padding: 20px 15px;
  justify-content: space-between;
}
.logo {
  font-size: 30px;
}
.bars {
  display: flex;
  font-size: 20px;
  margin-left: 50px;
}
.link {
  display: flex;
  color: #fff !important;
  padding: 10px 15px;
  gap: 15px;
  transition: all 0.5s;
  text-decoration: none;
}
.link:hover {
  color: #000;
  transition: all 0.5s;
  text-decoration: none;
}
.active {
  color: #000;
  text-decoration: underline;
  line-height: 10px;
}
.icon .link_text {
  font-size: 15px;
}
.link_text {
  font-size: 0.8rem;
}

@media screen and (max-width: 1100px) {
  .slidebar_container {
    display: none;
  }
}
