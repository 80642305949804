/* .appbar{
    width: 100%;
} */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,500;0,600;1,400;1,500&family=Roboto:ital,wght@0,100;0,300;1,500&display=swap");

.profile_name {
  background-color: #5156be;
  color: #fff;
  padding: 4px 8px;
  border-radius: 8px;
}
.barsAppbar {
  display: none;
}

.appbar_toggle_sidebar {
  justify-content: flex-start;
  margin: "auto";
  position: "absolute";
  top: 20%;
  left: 0;
  right: 0;
  bottom: 0;
}
.appbarSidebar_toggle {
  width: 150px;
  background-color: #5156be;
  z-index: 1;
}
.appbarSidebar_toggle a {
  text-decoration: none;
  color: #fff;
  font-size: 0.8rem;
}
.appbarSidebar_toggle button {
  text-decoration: none;
  color: #fff;
  text-transform: capitalize;
  font-size: 0.8rem;
  font-weight: 400;
}
@media only screen and (min-width: 1100px) {
  .appbars {
    display: none;
  }
}
