.assessmentoverview {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.assessmentoverview .assessmentoverview_barchart {
  flex: 2;
}
.assessmentoverview .assessmentoverview_content {
  flex: 1;
}
.barchart {
  width: 600px;
}
@media only screen and (max-width: 1200px) {
  .assessmentoverview {
    flex-direction: column;
    gap: 2rem;
    align-items: center;
  }
  .assessmentoverview .assessmentoverview_barchart {
    flex: 1;
  }
  .assessmentoverview .assessmentoverview_content {
    flex: 1;
  }
}

.recharts_surface {
  width: 100%;
}
