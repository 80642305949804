.scenaroio_progress {
  margin-top: -36px;
  flex: 1;
}
.scenaio_time p {
  margin: 0px;
  font-size: 0.7rem;
}
.scenaio_time {
  margin-top: 50px;
  margin-right: 40px;
  text-align: left;
}
.scenaio_time .scenaio_time_text_btn {
  display: flex;
  flex-direction: column;
}
.scenaio_time span {
  font-size: 1.4rem;
  font-weight: 600;
  margin-top: 5px;
}

.scenaio_time .scenario_view_report_btn {
  border: none;
  color: #ffffff;
  font-size: 0.6rem;
  padding: 4px 6px;
  border-radius: 3px;
  margin-top: 8px;
  background-color: rgb(86, 74, 177);
}

.scenaio_time .scenario_view_report_btn:hover {
  background-color: rgb(86, 74, 177);
}

@media screen and (max-width: 500px) {
  .scenario {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
  .scenaroio_progress {
    align-items: center;
  }
}
