.Prefrences_radio_labels {
  font-weight: 500;
  font-size: 0.9rem;
  font-family: "Poppins", sans-serif;
  color: #343a40;
}
.prefrence_cards {
  display: flex;
  gap: 15px;
  justify-content: space-between;

  margin: 20px;
}
.prefreence_main_radio_card {
  font-size: 15px;
  display: flex;
  margin: 0;
  align-items: center;
  gap: 100px;
  width: 50%;
}
.prefrence_label {
  width: 150px;
}
.markers {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.8rem;
  gap: 100px;
}
.markers label {
  width: 150px;
}
.radio {
  gap: 10px;
}
.radio span {
  font-size: 0.8rem;
}

@media screen and (max-width: 1293px) {
  .prefreence_main_radio_card {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
  .markers {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
}

@media screen and (max-width: 1290px) {
  .prefrence_cards {
    flex-direction: column;
    justify-content: center;
    margin: 20px;
  }
  .pref_btn {
    margin-bottom: 2.5rem;
  }
}
@media screen and (max-width: 600px) {
  .pref_btn {
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 600px) {
  .pref_btn {
    display: flex;
    justify-content: center;
  }
}
