@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,500;0,600;1,400;1,500&family=Roboto:ital,wght@0,100;0,300;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,500;0,600;1,400;1,500&family=Roboto:ital,wght@0,100;0,300;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,500;0,600;1,400;1,500&family=Roboto:ital,wght@0,100;0,300;1,500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif, poppins;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.modal-header button {
  color: #564d4d;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 1rem;
  border-radius: 50%;
  letter-spacing: 1px;
  background-color: #fff;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.modal-header button {
  background-color: #fff;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* logo */

.logo_img img {
  height: 80px;
  width: 80%;
}
.logo_text p {
  margin: 0;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 162px;
}
.header h6 {
  color: #495057;
  font-weight: 600;
}
.login_form {
  background-color: #ffffff;
}
.form_input_login label {
  font-size: 14px;
  color: #495057;
  letter-spacing: 1px;
  text-transform: capitalize;
}
.form_input_login input {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5px;
  border-radius: 8px;
  border: 1.5px solid #2a2a1038;
  outline: none;
}

.form_input_login {
  margin: 30px 0 10px 0;
  color: #495057;
}
.showBtn {
  display: flex;
}
.showBtn input {
  width: 90%;
  border-radius: 8px 0px 0px 8px;
}
.showBtn button {
  width: 10%;
  height: 39px;
  border-radius: 0 8px 8px 0;
  border: none;
  color: #b9b0b0;
}
.showHide {
  border: 1.5px solid #2a2a1038;
  border-left: none;
  padding: 4px;
  border-radius: 0 8px 8px 0;
  color: #b9b0b0;
}
.forgetpassword {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}
.checkbox {
  margin-top: 20px;
}
.login {
  text-align: center;
  margin-top: 20px;
}
.login button {
  width: 100%;
  padding: 5px;
  font-size: 0.8rem;
}

::-webkit-input-placeholder {
  font-size: 12px;
  text-transform: capitalize;
}

::placeholder {
  font-size: 12px;
  text-transform: capitalize;
}
.sign_In_With {
  margin-top: 30px;
}
.sign_In_With p {
  margin-top: -1rem;
}
.icons {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}
.signup {
  margin-top: 30px;
  font-size: 13px;
}
.signup p {
  color: #b9b0b0;
}
.copyright {
  margin-top: 208px;
  color: #837c7c;
  font-size: 14px;
}
/* .alert {
  margin-bottom: 1rem;
  border: 2px solid transparent;
  border-radius: 0.25rem;
} */
.alert_danger {
  color: #b14542;
  background-color: #fec0bf;
  border-color: #feb1af;
  margin: 0;
  border: 2px solid transparent;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 2px solid transparent;
  border-radius: 0.25rem;
  font-size: 0.7rem;
}

.MuiBox-root css-1obewpz {
  width: 100%;
  height: 1043px;
}

.form {
  margin-top: -60px;
}

h2 {
  color: #333;
  text-align: center;
  text-transform: uppercase;
  /* font-family: "Roboto", sans-serif; */
  font-weight: bold;
  position: relative;
  margin: 30px 0 60px;
}
h2::after {
  content: "";
  width: 100px;
  position: absolute;
  margin: 0 auto;
  height: 3px;
  background: #8fbc54;
  left: 0;
  right: 0;
  bottom: -10px;
}
.col-center {
  margin: 0 auto;
  float: none !important;
}
.carousel {
  padding: 0 70px;
}
.carousel .carousel-item {
  color: #999;
  font-size: 14px;
  text-align: center;
  overflow: hidden;
  min-height: 290px;
  background-color: transparent;
}
.carousel .carousel-item .img-box {
  width: 119px;
  height: 115px;
  /* margin: 0 auto; */
  /* padding: 5px; */
  border: 1px solid #ddd;
  border-radius: 50%;
  margin-top: -35px;
}
.carousel .img-box img {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 50%;
}
.carousel .testimonial {
  padding: 50px 0 10px;
  /* font-size: 20px; */
  font-size: 1.4rem;
  color: #d9cbcb;
  font-weight: 500;
}
.carousel .overview {
  margin-right: 438px;
  margin-top: -76px;
  color: #d9cbcb;
}
/* .carousel .overview b {
	text-transform: uppercase; 
	 color: black;
	
} */
.carousel-control-prev,
.carousel-control-next {
  width: 40px;
  height: 40px;
  margin-top: -20px;
  top: 50%;
  background: none;
}
.carousel-control-prev i,
.carousel-control-next i {
  font-size: 68px;
  line-height: 42px;
  position: absolute;
  display: inline-block;
  color: rgba(0, 0, 0, 0.8);
  text-shadow: 0 7px 7px #1b1818, 0 0 0 rgb(17, 8, 8);
}
.carousel-indicators {
  bottom: -40px;
  /* margin-top: 50px; */
}
.carousel-indicators li,
.carousel-indicators li.active {
  /* width: 12px; */
  height: 12px;
  margin: 1px 3px;
  border-radius: 50%;
  border: none;
}
.carousel-indicators li {
  background: #999;
  border-color: transparent;
  box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.2);
}
.carousel-indicators li.active {
  background: #555;
  box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* @media screen (max-width: 900px) {

} */

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}
.container {
  display: flex;
}

main {
  width: 100%;
  padding: 20px;
}
.sidebar {
  background: #5156be;
  color: #fff;
  height: 100vh;

  width: 100wh;
  transition: width 100wh, height 100vh;
  /* transition: all 0.5s; */
}
.top_section {
  display: flex;
  align-items: center;
  padding: 20px 15px;
  justify-content: space-between;
}
.logo {
  font-size: 30px;
}
.bars {
  display: flex;
  font-size: 20px;
  margin-left: 50px;
}
.link {
  display: flex;
  color: #fff !important;
  padding: 10px 15px;
  grid-gap: 15px;
  gap: 15px;
  transition: all 0.5s;
  text-decoration: none;
}
.link:hover {
  color: #000;
  transition: all 0.5s;
  text-decoration: none;
}
.active {
  color: #000;
  text-decoration: underline;
  line-height: 10px;
}
.icon .link_text {
  font-size: 15px;
}
.link_text {
  font-size: 0.8rem;
}

@media screen and (max-width: 1100px) {
  .slidebar_container {
    display: none;
  }
}

/* .appbar{
    width: 100%;
} */

.profile_name {
  background-color: #5156be;
  color: #fff;
  padding: 4px 8px;
  border-radius: 8px;
}
.barsAppbar {
  display: none;
}

.appbar_toggle_sidebar {
  justify-content: flex-start;
  margin: "auto";
  position: "absolute";
  top: 20%;
  left: 0;
  right: 0;
  bottom: 0;
}
.appbarSidebar_toggle {
  width: 150px;
  background-color: #5156be;
  z-index: 1;
}
.appbarSidebar_toggle a {
  text-decoration: none;
  color: #fff;
  font-size: 0.8rem;
}
.appbarSidebar_toggle button {
  text-decoration: none;
  color: #fff;
  text-transform: capitalize;
  font-size: 0.8rem;
  font-weight: 400;
}
@media only screen and (min-width: 1100px) {
  .appbars {
    display: none;
  }
}

/* td {
  text-align: center;
}
th {
  text-align: center;
} */

.userTableMain {
  margin: 20px;
  margin-bottom: 30px;
}
.userlistTable {
  /* marginTop: "28px",
                paddingRight: "24px",
                marginLeft: "17px",
                marginBottom: "10px",
                fontSize: "1rem",
                color: "#495057" */
  margin-top: 28px;
  padding-right: 24px;
  margin-left: 17px;
  margin-bottom: 10px;
  font-size: 1rem;
  color: #495057;
}
.userlistTable_table {
  font-size: 1rem;
  color: #495057;
}
.userlistTable_table thead {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  text-transform: capitalize;
  text-align: center;
}
.userlistTable_table th {
  font-size: 0.8rem;
  text-align: center;
  padding: 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.userlistTable_table td {
  font-size: 0.8rem;
  text-align: center;
}
.userlistTable_table tr {
  border-bottom: 1px solid #e9e9ef;
  padding: 10px;
}

.userList_heading {
  padding: 10px;
  padding-bottom: 2rem;
  border: 1px solid #e9e9ef;
  margin-bottom: 20px;
}

.userList_heading_span {
  border-bottom: 1px solid #e9e9ef;
  margin-bottom: 20px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.userList_heading_span_btn {
  padding: 5px;
  border: none;
  background-color: #141413;
  color: #ffffff;
  font-size: 0.8rem;
  box-shadow: 0 2px 6px 0 rgb(52 58 64 / 50%);
}
.userList_heading_span a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
.userList_heading_span a:hover {
  background-color: #141413;
  color: inherit;
  border-radius: 4px;
}

/* .userList_heading_span:hover {
  color: #ffffff;
} */
.userList_heading_span_conten {
  text-transform: capitalize;
}

.alert_danger {
  color: #b14542;
  background-color: #fec0bf;
  border-color: #feb1af;
  margin: 0;
  border: 2px solid transparent;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 2px solid transparent;
  border-radius: 0.25rem;
  font-size: 0.7rem;
}

.alert_green {
  color: black;
  background-color: #0080008f;
  border-color: #0080008f;
  margin: 0;
  border: 2px solid transparent;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 2px solid transparent;
  border-radius: 0.25rem;
  font-size: 0.7rem;
  text-align: center;
  width: 100%;
}

.editPasswordBtn {
  background-color: transparent;
  font-size: 0.8rem;
  font-weight: 500;

  text-transform: capitalize;
  border: none;
  cursor: pointer;
}

.editPasswordBtn::hover {
  background-color: tan;
  color: aqua;
}

.user_status span {
  color: red;
}

.scenaroio_progress {
  margin-top: -36px;
  flex: 1 1;
}
.scenaio_time p {
  margin: 0px;
  font-size: 0.7rem;
}
.scenaio_time {
  margin-top: 50px;
  margin-right: 40px;
  text-align: left;
}
.scenaio_time .scenaio_time_text_btn {
  display: flex;
  flex-direction: column;
}
.scenaio_time span {
  font-size: 1.4rem;
  font-weight: 600;
  margin-top: 5px;
}

.scenaio_time .scenario_view_report_btn {
  border: none;
  color: #ffffff;
  font-size: 0.6rem;
  padding: 4px 6px;
  border-radius: 3px;
  margin-top: 8px;
  background-color: rgb(86, 74, 177);
}

.scenaio_time .scenario_view_report_btn:hover {
  background-color: rgb(86, 74, 177);
}

@media screen and (max-width: 500px) {
  .scenario {
    flex-direction: column;
    align-items: center;
    grid-gap: 30px;
    gap: 30px;
  }
  .scenaroio_progress {
    align-items: center;
  }
}

.assessmentoverview {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.assessmentoverview .assessmentoverview_barchart {
  flex: 2 1;
}
.assessmentoverview .assessmentoverview_content {
  flex: 1 1;
}
.barchart {
  width: 600px;
}
@media only screen and (max-width: 1200px) {
  .assessmentoverview {
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
    align-items: center;
  }
  .assessmentoverview .assessmentoverview_barchart {
    flex: 1 1;
  }
  .assessmentoverview .assessmentoverview_content {
    flex: 1 1;
  }
}

.recharts_surface {
  width: 100%;
}

.userdetails_main {
  display: flex;
  background-color: #ffffff;
}
.main_card {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  margin-left: 20px;
  flex-wrap: wrap;
  margin-right: 20px;
}
.userdetails_main .userdetails_admin {
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 0.8rem;
}
.userdetails_main .userdetails_admin p {
  font-size: 0.8rem;
}
.userdetails_role_cards_content {
  display: flex;
  justify-content: space-between;
}
.userdetails_role_cards_content .userdetails_role_cards_content_groupicon {
  font-size: 50px;
  color: #495057;
}

.userdetails_role_cards_content .userdetails_role_cards_content_text {
  display: flex;
  flex-direction: column;

  font-weight: 400;
  font-size: 0.8rem;
}
.userdetails_role_cards_content .userdetails_role_cards_content_text span {
  color: #495057;
}
.userdetails_main .userdetails_second_main_card {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  grid-gap: 10px;
  gap: 10px;
  margin: 20px;
  flex-wrap: wrap;
}
.userdetails_second_main_card .userdetails_second_main_card_text {
  color: #495057;
  font-size: 0.8rem;
  font-weight: 400;
}
.sales_by_locations_recent_activiteis {
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  grid-gap: 20px;
  gap: 20px;
  margin-top: 50px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}
.sales_by_locations_recent_activiteis .sales_by_locations_p {
  color: black;
  font-weight: 400;
  font-size: 0.9rem;
  padding: 13px;
  margin: 0;
}
.recent_activiteis {
  display: flex;
  justify-content: space-between;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;

  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  padding: 10px;
}
.recent_activiteis .recent_activiteis_p {
  color: black;
  font-weight: 400;
  font-size: 0.9rem;
}

.recent_activiteis p {
  font-size: 15px;
}
.recent_activiteis .recent_activiteis_options {
  font-size: 0.75rem;
  border-radius: 4px;
  border: 2px solid #d9cfcf;
  text-transform: capitalize;
  font-weight: 400;
}
.online_users_details {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-left: 1rem;
  flex: 1 1;
}
.online_users_details p {
  font-size: 0.9rem;
  text-transform: capitalize;
  color: #495057;
}
.online_users_details .online_users_city {
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
  color: #495057;
  font-size: 0.9rem;
}
.online_users_details .online_users_city .online_users_city_first {
  color: #13c849;
  font-size: 0.9rem;
  text-transform: capitalize;
  width: 150px;
}
.online_users_details .online_users_city .online_users_city_last {
  color: rgb(40, 158, 196);
  font-size: 0.9rem;
  font-weight: 600;
}

::-webkit-scrollbar {
  width: 10px;
}

@media only screen and (max-width: 900px) {
  .main_card {
    flex-direction: column;
    margin: 20px;
  }

  .sales_by_locations_recent_activiteis {
    flex-direction: column;
  }
  .recent_activiteis_main {
    margin-bottom: 2.4rem;
  }
}

@media only screen and (max-width: 1330px) {
  .userdetails_second_main_card {
    flex-direction: column;
    margin: 0 20px;
  }
}

.userActivities {
  padding: 10px 30px;
}

.userActivities_date_activity_main {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 20px;
}
.userActivities {
  font-size: 1rem;
  display: flex;
  flex-direction: column;
}
.userActivities .userActivities_date_activity span {
  font-size: 0.8rem;
  font-weight: 500;
  color: #495057;
}

.userActivities .userActivities_date_activity .userActivities_actvity {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
}
.userActivities .userActivities_date_activity .userActivities_actvity p {
  margin: 0;
  font-size: 0.8rem;
  font-weight: 400;
  color: #74788d;
}
.userActivities
  .userActivities_date_activity
  .userActivities_actvity
  :nth-child(1) {
  text-transform: capitalize;
}
.userActivities_date_activity_main_icon {
  background-color: rgb(226, 208, 244);
  border-radius: 50%;
  font-size: 38px;
  padding: 10px;
  padding: 10px;
}

.adduser_main {
  display: flex;
  background-color: #ffffff;
}
.adduser_main .adduser_main_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
}
.adduser_main .adduser_main_content .adduser_main_content_user {
  display: flex;
  margin-top: 20px;
  margin-left: 27px;
  font-weight: 600;
  font-size: 0.9rem;
}
.adduser_main .adduser_main_content .adduser_main_content_user p {
  margin: 0;
}
.adduser_main .adduser_main_content .adduser_main_content_admin {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  padding: 10px;
}
.adduser_comp {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 84vh;
}
.adduser_comp_form form {
  margin-top: 30px;
  padding: 2rem;

  margin: 10px;

  border-radius: 5px;
}

.adduser_comp_form {
  flex: 1 1;
}
.adduser_comp_form input {
  font-size: 0.8rem;

  border: none;
  outline: none;
  border-radius: 0%;
  border-bottom: 1.5px solid grey;
  width: 100%;
  background-color: #ffffff;
  padding: 0 5px;
}
.adduser_comp_form_label label {
  font-size: 0.8rem;
}

.adduser_comp_form .error {
  color: rgb(229 91 91);
  font-size: 0.9rem;
  letter-spacing: 1px;
  text-transform: lowercase;
  font-size: 0.8rem;
}
.alert_danger {
  color: #b14542;
  background-color: #fec0bf;
  border-color: #feb1af;
  margin: 0;
  border: 2px solid transparent;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 2px solid transparent;
  border-radius: 0.25rem;
  font-size: 0.7rem;
}
@media screen and (max-width: 900px) {
  .adduser_comp_form form {
    margin-bottom: 3.5rem;
  }
}

body {
  overflow: hidden;
  background: #eee;
}

.number {
  position: relative;
  text-align: center;
  -webkit-animation: mymove 1s forwards;
          animation: mymove 1s forwards;
}
.number::before {
  content: "";
  display: block;
  position: absolute;
  left: 0%;
  right: 0%;
  margin: auto;
  width: 210px;
  height: 100px;
  background: #bf360c;
  -webkit-clip-path: polygon(
    0% 97%,
    0% 90%,
    25% 80%,
    30% 10%,
    40% 0%,
    50% 5%,
    60% 0%,
    70% 10%,
    75% 80%,
    100% 90%,
    100% 97%,
    50% 100%
  );
          clip-path: polygon(
    0% 97%,
    0% 90%,
    25% 80%,
    30% 10%,
    40% 0%,
    50% 5%,
    60% 0%,
    70% 10%,
    75% 80%,
    100% 90%,
    100% 97%,
    50% 100%
  );
  border-radius: 30px;
}
.number::after {
  content: "oops page not found ";
  display: block;
  font-size: 20%;
}

.btn {
  position: relative;
  top: 150px;

  margin: 0 auto;
  background: none;
  border: 2px solid #bf360c;
  border-radius: 5px;
}

.tumble {
  position: absolute;
  bottom: 0;
  width: 100px;
  height: 100px;
  left: -120px;
  background: url("https://vignette.wikia.nocookie.net/simpsonstappedout/images/3/39/Tumbleweed_Unlock.png/revision/latest?cb=20160426192512");
  background-size: contain;
  -webkit-animation: tumbleRoll 9.5s infinite forwards;
          animation: tumbleRoll 9.5s infinite forwards;
}

/* Standard syntax */
@-webkit-keyframes mymove {
  0% {
    top: 0px;
    font-size: initial;
  }
  100% {
    top: 100px;
    font-size: 200px;
  }
}
@keyframes mymove {
  0% {
    top: 0px;
    font-size: initial;
  }
  100% {
    top: 100px;
    font-size: 200px;
  }
}

@-webkit-keyframes tumbleRoll {
  0% {
    left: -120px;
  }
  10% {
    bottom: 30px;
  }
  15% {
    bottom: 0px;
  }
  40% {
    bottom: 30px;
  }
  55% {
    bottom: 0px;
  }
  70% {
    bottom: 30px;
  }
  75% {
    bottom: 0px;
  }
  100% {
    left: 100vw;
    -webkit-transform: rotate(600deg);
            transform: rotate(600deg);
  }
}

@keyframes tumbleRoll {
  0% {
    left: -120px;
  }
  10% {
    bottom: 30px;
  }
  15% {
    bottom: 0px;
  }
  40% {
    bottom: 30px;
  }
  55% {
    bottom: 0px;
  }
  70% {
    bottom: 30px;
  }
  75% {
    bottom: 0px;
  }
  100% {
    left: 100vw;
    -webkit-transform: rotate(600deg);
            transform: rotate(600deg);
  }
}

.onlineUsers_main {
  display: flex;
  background-color: #fff;
}
.onlineUsers_main .onlineUsers_main_count {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
}
.onlineUsers_main .onlineUsers_main_count .user {
  margin-top: 20px;
  display: flex;
  margin-left: 27px;
  font-weight: 500;
  color: #495057;
}
.onlineUsers_main .onlineUsers_main_count .add_new {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  padding: 10px;
}
.onlineUsers_main .onlineUsers_main_count .add_new .online_btn {
  color: "#000";
  background-color: #5156be;
  font-size: 0.9rem;
}
.onlineUsers_main .onlineUsers_card {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  grid-gap: 30px;
  gap: 30px;
  margin: 20px;
  margin-bottom: 5rem;
}
.onlineUsers_main .onlineUsers_card .onlineUsers_card_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 150px;
  flex: 1 1;
  border: 1.5px solid #ebdede;
}
.onlineUsers_main .onlineUsers_card .onlineUsers_card_content span {
  font-size: 14px;
  font-weight: 500;
  color: #495057;
}
.onlineUsers_main .onlineUsers_card .onlineUsers_card_content p {
  font-size: 0.7px;

  color: #495057;
}

.userInfo p {
  font-size: 0.8rem;
}
.userinfotxt {
  display: flex;
  font-size: 0.8rem;
  text-transform: capitalize;
  color: #343a40;
  font-weight: 500;
}

.userinfotxt p {
  margin-left: 10px;
  font-size: 0.8rem;
  text-transform: lowercase;
  color: #495057;
  font-weight: 400;
}
.view_report td {
  padding: 0.75rem;
  font-size: 0.7rem;
  color: #495057;
}
.modal-header button {
  background-color: #ffffff;
}
.view_report th {
  padding: 0.75rem;
  font-size: 0.7rem;
  color: #495057;
}
.view_report thead tr th {
  padding: 0.75rem;
  font-size: 0.8rem;
  color: #495057;
}
.itemlist_status_available td:last-child {
  background-color: #d4f0e5;
}

.itemlist_status_not_available td:last-child {
  background-color: #ffe0df;
}
.modal-header button {
  background-color: #fff;
}
.btn-close {
  background-color: #343a40;
}

@media only screen and (max-width: 756px) {
  .onlineUsers_main .onlineUsers_card {
    justify-content: center;
  }
}

.forget_sub-heading {
  text-align: center;
  text-transform: lowercase;
  font-size: 0.9rem;
  color: #7e7575;
  align-self: center;
}
.forget_heading {
  align-self: center;
}

.forget_sub-heading p::first-letter {
  text-transform: capitalize;
}
.formforget .form-button {
  border-radius: 11px;
  background-color: rgb(81, 86, 190);
  color: #ffff;
  width: 100%;
  font-size: 1rem;
  text-transform: capitalize;
  padding: 4px;
}
.formforget .form-button:hover {
  background-color: rgb(81, 86, 190);
}
.formforget input {
  width: 100%;
  border: none;
  background: transparent;
  border-bottom: 2px solid #7e7575;
  margin: 10px 0;
  outline: none;
}
.log_img {
  align-self: center;
}
.log_img img {
  width: 200px;
  object-fit: cover;
}
.alert_danger {
  color: #b14542;
  background-color: #fec0bf;
  border-color: #feb1af;
  margin: 0;
  border: 2px solid transparent;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 2px solid transparent;
  border-radius: 0.25rem;
  font-size: 0.7rem;
  text-align: center;
}
.alert_green {
  color: black;
  background-color: #0080008f;
  border-color: #0080008f;
  margin: 0;
  border: 2px solid transparent;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 2px solid transparent;
  border-radius: 0.25rem;
  font-size: 0.7rem;
  text-align: center;
}
.emailsend {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 404px;
  padding: 10px;
}
.emailsend h3 {
  font-weight: 800;
}

.emailsend h6 {
  color: #464040;
  text-align: justify;
  font-size: 0.9rem;
  line-height: 20px;
  font-weight: 500;
}

.Prefrences_radio_labels {
  font-weight: 500;
  font-size: 0.9rem;
  font-family: "Poppins", sans-serif;
  color: #343a40;
}
.prefrence_cards {
  display: flex;
  grid-gap: 15px;
  gap: 15px;
  justify-content: space-between;

  margin: 20px;
}
.prefreence_main_radio_card {
  font-size: 15px;
  display: flex;
  margin: 0;
  align-items: center;
  grid-gap: 100px;
  gap: 100px;
  width: 50%;
}
.prefrence_label {
  width: 150px;
}
.markers {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.8rem;
  grid-gap: 100px;
  gap: 100px;
}
.markers label {
  width: 150px;
}
.radio {
  grid-gap: 10px;
  gap: 10px;
}
.radio span {
  font-size: 0.8rem;
}

@media screen and (max-width: 1293px) {
  .prefreence_main_radio_card {
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 0;
    gap: 0;
  }
  .markers {
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 0;
    gap: 0;
  }
}

@media screen and (max-width: 1290px) {
  .prefrence_cards {
    flex-direction: column;
    justify-content: center;
    margin: 20px;
  }
  .pref_btn {
    margin-bottom: 2.5rem;
  }
}
@media screen and (max-width: 600px) {
  .pref_btn {
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 600px) {
  .pref_btn {
    display: flex;
    justify-content: center;
  }
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  height: 62px;
  border: 1px solid #e1dfdf;
  align-items: center;
  padding: 0 30px;
  flex-wrap: wrap;
  background-color: #ffffff;
}
.footer p {
  margin: 0;
  font-size: 0.9rem;
}
.footer .footer_left p {
  text-transform: uppercase;
  letter-spacing: 1px;
}
.footer .footer_right p {
  text-transform: capitalize;
  letter-spacing: 1px;
}

@media screen and (max-width: 300px) {
  .footer {
    justify-content: center;
  }
}

.userlistTable_table_useract {
  font-size: 1rem;
  color: #495057;

  margin-bottom: 2.4rem;
}
.userlistTable_table_useract thead {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  text-transform: capitalize;
  text-align: center;
}
.userlistTable_table_useract th {
  font-size: 0.8rem;
  text-align: center;
  padding: 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.userlistTable_table_useract td {
  font-size: 0.8rem;
  text-align: center;
  padding: 10px;
}
.userlistTable_table_useract tr {
  border-bottom: 1px solid #e9e9ef;
  padding: 10px;
}

.userList_heading {
  padding: 10px;
  padding-bottom: 2rem;
  border: 1px solid #e9e9ef;
  margin-bottom: 20px;
}

.userList_heading_span {
  border-bottom: 1px solid #e9e9ef;
  margin-bottom: 20px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.userList_heading_span_btn {
  padding: 5px;
  border: none;
  background-color: #141413;
  color: #ffffff;
  font-size: 0.8rem;
  box-shadow: 0 2px 6px 0 rgb(52 58 64 / 50%);
}
.userList_heading_span a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
.userList_heading_span a:hover {
  background-color: #141413;
  color: inherit;
  border-radius: 4px;
}

/* .userList_heading_span:hover {
  color: #ffffff;
} */
.userList_heading_span_conten {
  text-transform: capitalize;
}

.alert_danger {
  color: #b14542;
  background-color: #fec0bf;
  border-color: #feb1af;
  margin: 0;
  border: 2px solid transparent;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 2px solid transparent;
  border-radius: 0.25rem;
  font-size: 0.7rem;
}

