.userdetails_main {
  display: flex;
  background-color: #ffffff;
}
.main_card {
  display: flex;
  gap: 10px;
  margin-left: 20px;
  flex-wrap: wrap;
  margin-right: 20px;
}
.userdetails_main .userdetails_admin {
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 0.8rem;
}
.userdetails_main .userdetails_admin p {
  font-size: 0.8rem;
}
.userdetails_role_cards_content {
  display: flex;
  justify-content: space-between;
}
.userdetails_role_cards_content .userdetails_role_cards_content_groupicon {
  font-size: 50px;
  color: #495057;
}

.userdetails_role_cards_content .userdetails_role_cards_content_text {
  display: flex;
  flex-direction: column;

  font-weight: 400;
  font-size: 0.8rem;
}
.userdetails_role_cards_content .userdetails_role_cards_content_text span {
  color: #495057;
}
.userdetails_main .userdetails_second_main_card {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  gap: 10px;
  margin: 20px;
  flex-wrap: wrap;
}
.userdetails_second_main_card .userdetails_second_main_card_text {
  color: #495057;
  font-size: 0.8rem;
  font-weight: 400;
}
.sales_by_locations_recent_activiteis {
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  gap: 20px;
  margin-top: 50px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}
.sales_by_locations_recent_activiteis .sales_by_locations_p {
  color: black;
  font-weight: 400;
  font-size: 0.9rem;
  padding: 13px;
  margin: 0;
}
.recent_activiteis {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;

  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  padding: 10px;
}
.recent_activiteis .recent_activiteis_p {
  color: black;
  font-weight: 400;
  font-size: 0.9rem;
}

.recent_activiteis p {
  font-size: 15px;
}
.recent_activiteis .recent_activiteis_options {
  font-size: 0.75rem;
  border-radius: 4px;
  border: 2px solid #d9cfcf;
  text-transform: capitalize;
  font-weight: 400;
}
.online_users_details {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-left: 1rem;
  flex: 1;
}
.online_users_details p {
  font-size: 0.9rem;
  text-transform: capitalize;
  color: #495057;
}
.online_users_details .online_users_city {
  display: flex;
  gap: 2rem;
  color: #495057;
  font-size: 0.9rem;
}
.online_users_details .online_users_city .online_users_city_first {
  color: #13c849;
  font-size: 0.9rem;
  text-transform: capitalize;
  width: 150px;
}
.online_users_details .online_users_city .online_users_city_last {
  color: rgb(40, 158, 196);
  font-size: 0.9rem;
  font-weight: 600;
}

::-webkit-scrollbar {
  width: 10px;
}

@media only screen and (max-width: 900px) {
  .main_card {
    flex-direction: column;
    margin: 20px;
  }

  .sales_by_locations_recent_activiteis {
    flex-direction: column;
  }
  .recent_activiteis_main {
    margin-bottom: 2.4rem;
  }
}

@media only screen and (max-width: 1330px) {
  .userdetails_second_main_card {
    flex-direction: column;
    margin: 0 20px;
  }
}
