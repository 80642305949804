h2 {
  color: #333;
  text-align: center;
  text-transform: uppercase;
  /* font-family: "Roboto", sans-serif; */
  font-weight: bold;
  position: relative;
  margin: 30px 0 60px;
}
h2::after {
  content: "";
  width: 100px;
  position: absolute;
  margin: 0 auto;
  height: 3px;
  background: #8fbc54;
  left: 0;
  right: 0;
  bottom: -10px;
}
.col-center {
  margin: 0 auto;
  float: none !important;
}
.carousel {
  padding: 0 70px;
}
.carousel .carousel-item {
  color: #999;
  font-size: 14px;
  text-align: center;
  overflow: hidden;
  min-height: 290px;
  background-color: transparent;
}
.carousel .carousel-item .img-box {
  width: 119px;
  height: 115px;
  /* margin: 0 auto; */
  /* padding: 5px; */
  border: 1px solid #ddd;
  border-radius: 50%;
  margin-top: -35px;
}
.carousel .img-box img {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 50%;
}
.carousel .testimonial {
  padding: 50px 0 10px;
  /* font-size: 20px; */
  font-size: 1.4rem;
  color: #d9cbcb;
  font-weight: 500;
}
.carousel .overview {
  margin-right: 438px;
  margin-top: -76px;
  color: #d9cbcb;
}
/* .carousel .overview b {
	text-transform: uppercase; 
	 color: black;
	
} */
.carousel-control-prev,
.carousel-control-next {
  width: 40px;
  height: 40px;
  margin-top: -20px;
  top: 50%;
  background: none;
}
.carousel-control-prev i,
.carousel-control-next i {
  font-size: 68px;
  line-height: 42px;
  position: absolute;
  display: inline-block;
  color: rgba(0, 0, 0, 0.8);
  text-shadow: 0 7px 7px #1b1818, 0 0 0 rgb(17, 8, 8);
}
.carousel-indicators {
  bottom: -40px;
  /* margin-top: 50px; */
}
.carousel-indicators li,
.carousel-indicators li.active {
  /* width: 12px; */
  height: 12px;
  margin: 1px 3px;
  border-radius: 50%;
  border: none;
}
.carousel-indicators li {
  background: #999;
  border-color: transparent;
  box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.2);
}
.carousel-indicators li.active {
  background: #555;
  box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* @media screen (max-width: 900px) {

} */
