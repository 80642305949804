.editPasswordBtn {
  background-color: transparent;
  font-size: 0.8rem;
  font-weight: 500;

  text-transform: capitalize;
  border: none;
  cursor: pointer;
}

.editPasswordBtn::hover {
  background-color: tan;
  color: aqua;
}
