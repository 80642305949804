.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  height: 62px;
  border: 1px solid #e1dfdf;
  align-items: center;
  padding: 0 30px;
  flex-wrap: wrap;
  background-color: #ffffff;
}
.footer p {
  margin: 0;
  font-size: 0.9rem;
}
.footer .footer_left p {
  text-transform: uppercase;
  letter-spacing: 1px;
}
.footer .footer_right p {
  text-transform: capitalize;
  letter-spacing: 1px;
}

@media screen and (max-width: 300px) {
  .footer {
    justify-content: center;
  }
}
