.onlineUsers_main {
  display: flex;
  background-color: #fff;
}
.onlineUsers_main .onlineUsers_main_count {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
}
.onlineUsers_main .onlineUsers_main_count .user {
  margin-top: 20px;
  display: flex;
  margin-left: 27px;
  font-weight: 500;
  color: #495057;
}
.onlineUsers_main .onlineUsers_main_count .add_new {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
}
.onlineUsers_main .onlineUsers_main_count .add_new .online_btn {
  color: "#000";
  background-color: #5156be;
  font-size: 0.9rem;
}
.onlineUsers_main .onlineUsers_card {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 30px;
  margin: 20px;
  margin-bottom: 5rem;
}
.onlineUsers_main .onlineUsers_card .onlineUsers_card_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 150px;
  flex: 1;
  border: 1.5px solid #ebdede;
}
.onlineUsers_main .onlineUsers_card .onlineUsers_card_content span {
  font-size: 14px;
  font-weight: 500;
  color: #495057;
}
.onlineUsers_main .onlineUsers_card .onlineUsers_card_content p {
  font-size: 0.7px;

  color: #495057;
}

.userInfo p {
  font-size: 0.8rem;
}
.userinfotxt {
  display: flex;
  font-size: 0.8rem;
  text-transform: capitalize;
  color: #343a40;
  font-weight: 500;
}

.userinfotxt p {
  margin-left: 10px;
  font-size: 0.8rem;
  text-transform: lowercase;
  color: #495057;
  font-weight: 400;
}
.view_report td {
  padding: 0.75rem;
  font-size: 0.7rem;
  color: #495057;
}
.modal-header button {
  background-color: #ffffff;
}
.view_report th {
  padding: 0.75rem;
  font-size: 0.7rem;
  color: #495057;
}
.view_report thead tr th {
  padding: 0.75rem;
  font-size: 0.8rem;
  color: #495057;
}
.itemlist_status_available td:last-child {
  background-color: #d4f0e5;
}

.itemlist_status_not_available td:last-child {
  background-color: #ffe0df;
}
.modal-header button {
  background-color: #fff;
}
.btn-close {
  background-color: #343a40;
}

@media only screen and (max-width: 756px) {
  .onlineUsers_main .onlineUsers_card {
    justify-content: center;
  }
}
