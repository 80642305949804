/* logo */

.logo_img img {
  height: 80px;
  width: 80%;
}
.logo_text p {
  margin: 0;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 162px;
}
.header h6 {
  color: #495057;
  font-weight: 600;
}
.login_form {
  background-color: #ffffff;
}
.form_input_login label {
  font-size: 14px;
  color: #495057;
  letter-spacing: 1px;
  text-transform: capitalize;
}
.form_input_login input {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5px;
  border-radius: 8px;
  border: 1.5px solid #2a2a1038;
  outline: none;
}

.form_input_login {
  margin: 30px 0 10px 0;
  color: #495057;
}
.showBtn {
  display: flex;
}
.showBtn input {
  width: 90%;
  border-radius: 8px 0px 0px 8px;
}
.showBtn button {
  width: 10%;
  height: 39px;
  border-radius: 0 8px 8px 0;
  border: none;
  color: #b9b0b0;
}
.showHide {
  border: 1.5px solid #2a2a1038;
  border-left: none;
  padding: 4px;
  border-radius: 0 8px 8px 0;
  color: #b9b0b0;
}
.forgetpassword {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}
.checkbox {
  margin-top: 20px;
}
.login {
  text-align: center;
  margin-top: 20px;
}
.login button {
  width: 100%;
  padding: 5px;
  font-size: 0.8rem;
}

::placeholder {
  font-size: 12px;
  text-transform: capitalize;
}
.sign_In_With {
  margin-top: 30px;
}
.sign_In_With p {
  margin-top: -1rem;
}
.icons {
  display: flex;
  gap: 10px;
}
.signup {
  margin-top: 30px;
  font-size: 13px;
}
.signup p {
  color: #b9b0b0;
}
.copyright {
  margin-top: 208px;
  color: #837c7c;
  font-size: 14px;
}
/* .alert {
  margin-bottom: 1rem;
  border: 2px solid transparent;
  border-radius: 0.25rem;
} */
.alert_danger {
  color: #b14542;
  background-color: #fec0bf;
  border-color: #feb1af;
  margin: 0;
  border: 2px solid transparent;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 2px solid transparent;
  border-radius: 0.25rem;
  font-size: 0.7rem;
}

.MuiBox-root css-1obewpz {
  width: 100%;
  height: 1043px;
}

.form {
  margin-top: -60px;
}
