.userActivities {
  padding: 10px 30px;
}

.userActivities_date_activity_main {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}
.userActivities {
  font-size: 1rem;
  display: flex;
  flex-direction: column;
}
.userActivities .userActivities_date_activity span {
  font-size: 0.8rem;
  font-weight: 500;
  color: #495057;
}

.userActivities .userActivities_date_activity .userActivities_actvity {
  display: flex;
  gap: 5px;
}
.userActivities .userActivities_date_activity .userActivities_actvity p {
  margin: 0;
  font-size: 0.8rem;
  font-weight: 400;
  color: #74788d;
}
.userActivities
  .userActivities_date_activity
  .userActivities_actvity
  :nth-child(1) {
  text-transform: capitalize;
}
.userActivities_date_activity_main_icon {
  background-color: rgb(226, 208, 244);
  border-radius: 50%;
  font-size: 38px;
  padding: 10px;
  padding: 10px;
}
