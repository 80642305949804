.userlistTable_table_useract {
  font-size: 1rem;
  color: #495057;

  margin-bottom: 2.4rem;
}
.userlistTable_table_useract thead {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  text-transform: capitalize;
  text-align: center;
}
.userlistTable_table_useract th {
  font-size: 0.8rem;
  text-align: center;
  padding: 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.userlistTable_table_useract td {
  font-size: 0.8rem;
  text-align: center;
  padding: 10px;
}
.userlistTable_table_useract tr {
  border-bottom: 1px solid #e9e9ef;
  padding: 10px;
}

.userList_heading {
  padding: 10px;
  padding-bottom: 2rem;
  border: 1px solid #e9e9ef;
  margin-bottom: 20px;
}

.userList_heading_span {
  border-bottom: 1px solid #e9e9ef;
  margin-bottom: 20px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.userList_heading_span_btn {
  padding: 5px;
  border: none;
  background-color: #141413;
  color: #ffffff;
  font-size: 0.8rem;
  box-shadow: 0 2px 6px 0 rgb(52 58 64 / 50%);
}
.userList_heading_span a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
.userList_heading_span a:hover {
  background-color: #141413;
  color: inherit;
  border-radius: 4px;
}

/* .userList_heading_span:hover {
  color: #ffffff;
} */
.userList_heading_span_conten {
  text-transform: capitalize;
}

.alert_danger {
  color: #b14542;
  background-color: #fec0bf;
  border-color: #feb1af;
  margin: 0;
  border: 2px solid transparent;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 2px solid transparent;
  border-radius: 0.25rem;
  font-size: 0.7rem;
}
