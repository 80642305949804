.adduser_main {
  display: flex;
  background-color: #ffffff;
}
.adduser_main .adduser_main_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
}
.adduser_main .adduser_main_content .adduser_main_content_user {
  display: flex;
  margin-top: 20px;
  margin-left: 27px;
  font-weight: 600;
  font-size: 0.9rem;
}
.adduser_main .adduser_main_content .adduser_main_content_user p {
  margin: 0;
}
.adduser_main .adduser_main_content .adduser_main_content_admin {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  padding: 10px;
}
.adduser_comp {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 84vh;
}
.adduser_comp_form form {
  margin-top: 30px;
  padding: 2rem;

  margin: 10px;

  border-radius: 5px;
}

.adduser_comp_form {
  flex: 1;
}
.adduser_comp_form input {
  font-size: 0.8rem;

  border: none;
  outline: none;
  border-radius: 0%;
  border-bottom: 1.5px solid grey;
  width: 100%;
  background-color: #ffffff;
  padding: 0 5px;
}
.adduser_comp_form_label label {
  font-size: 0.8rem;
}

.adduser_comp_form .error {
  color: rgb(229 91 91);
  font-size: 0.9rem;
  letter-spacing: 1px;
  text-transform: lowercase;
  font-size: 0.8rem;
}
.alert_danger {
  color: #b14542;
  background-color: #fec0bf;
  border-color: #feb1af;
  margin: 0;
  border: 2px solid transparent;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 2px solid transparent;
  border-radius: 0.25rem;
  font-size: 0.7rem;
}
@media screen and (max-width: 900px) {
  .adduser_comp_form form {
    margin-bottom: 3.5rem;
  }
}
